import "./user-registration.scss";
import { track } from "jiffy-analytics";
import { on as onEvent } from "common/utils/ui/event";

const handleInvalidEmailOrPassword = () => {
  onEvent(
    "invalid",
    ".js-user-registration-email",
    function () {
      this.setCustomValidity("Please enter your email here");
    },
    true
  );
  onEvent(
    "invalid",
    ".js-user-registration-email-confirmation",
    function () {
      this.setCustomValidity("Please re-enter your email here");
    },
    true
  );
  onEvent(
    "invalid",
    ".js-user-registration-password",
    function () {
      this.setCustomValidity("Please enter your password here");
    },
    true
  );
  onEvent(
    "invalid",
    ".js-user-registration-password-confirmation",
    function () {
      this.setCustomValidity("Please re-enter your password here");
    },
    true
  );
};

const handleEmailAndPasswordInput = () => {
  onEvent(
    "input",
    `.js-user-registration-email, .js-user-registration-email-confirmation,
    .js-user-registration-password, .js-user-registration-password-confirmation`,
    function () {
      this.setCustomValidity("");
    },
    true
  );
};

const handleResponse = () => {
  const $$errorsContainer = $("#sign-up-errors");
  const $$errorsElement = $("#sign-up-errors .flash-message__message")[0];

  $("#new_spree_user").on({
    "ajax:before": function () {
      $$errorsContainer.addClass("hide");
    },
    "ajax:error": function (_event, xhr) {
      $$errorsContainer.removeClass("hide");
      $$errorsElement.innerHTML = JSON.parse(xhr.responseText).error_message;
      $("#new_spree_user").trigger("reset");
    },
    "ajax:success": function (_event, data) {
      window.dataLayer.push({
        event: "SignUp",
        email: data.email,
        eventId: data.signup_event_id
      });
      window.location.href = data.redirect_url;
    }
  });
};

export default () => {
  const $button = document.querySelector(".js-user-registration-submit-button");

  if ($button) {
    track("create_account_initiated");
  }

  handleInvalidEmailOrPassword();
  handleEmailAndPasswordInput();
  handleResponse();
};
